@import "~ngx-toastr/toastr";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Poppins", sans-serif;
}
.row {
  margin: 0 -15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.col-9 {
  width: 75%;
  padding: 0 15px;
}
.col-7 {
  width: 58.33%;
  padding: 0 15px;
}
.col-6 {
  padding: 0 15px;
  width: 50%;
}
.col-5 {
  width: 41.66%;
  padding: 0 15px;
}
.col-4 {
  width: 33.33%;
  padding: 0 15px;
}
.col-3 {
  width: 25%;
  padding: 0 15px;
}
.col-2 {
  width: 16.66%;
  padding: 0 15px;
}
.spacing {
  padding-bottom: clamp(30px, 10vw, 184px);
}
.btn {
  color: #0f6534 !important;
  padding: 10px 20px;
  background: #ffffff;
  border: 2px solid #0f6534;
  box-shadow: 0px 10px 16px -4px rgb(1 54 24 / 20%);
  border-radius: 32px;
  font-weight: bold;
  font-size: clamp(16px, 1.25vw, 24px);
  cursor: pointer;
}
.heading {
  padding-top: clamp(30px, 5.5vw, 100px);
  padding-bottom: clamp(30px, 5.5vw, 100px);
}

.heading h1 {
  color: #1d1d1d;
}
.content-heading p,
.plan-heading p {
  word-break: break-word;
}
.find-a-tutor-btn {
  border: 2px solid black;
  background: #0f6534;
  color: #fff !important;
}
.form-group img {
  transition: transform 0.3s;
  -webkit-transition: transform 0.3s;
  -moz-transition: transform 0.3s;
  -ms-transition: transform 0.3s;
  -o-transition: transform 0.3s;
}
.form-group select:focus ~ img {
  transform: rotateZ(180deg) !important;
  -webkit-transform: rotateZ(180deg) !important;
  -moz-transform: rotateZ(180deg) !important;
  -ms-transform: rotateZ(180deg) !important;
  -o-transform: rotateZ(180deg) !important;
}

.required:after {
  content: " *";
  color: red;
}

@media screen and (min-width: 1440px) {
  .container {
    padding: 0 80px;
    margin: auto;
  }
  .review-card p,
  .content-heading p,
  .heading p,
  .plan-heading p,
  .about-heading p,
  .faq-panel p {
    font-size: 22px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1439px) {
  .container {
    padding: 0 50px;
    margin: auto;
  }
  .review-card p,
  .content-heading p,
  .heading p,
  .plan-heading p,
  .about-heading p,
  .faq-panel p {
    font-size: 16px;
  }
}
@media screen and (min-width: 320px) and (max-width: 1024px) {
  .review-card p,
  .content-heading p,
  .heading p,
  .plan-heading p,
  .about-heading p,
  .faq-panel p {
    font-size: 12px;
  }
}
@media screen and (min-width: 1025px) {
  .heading h1 {
    font-size: clamp(45px, 4.168vw, 80px);
  }
  .content-heading h1 {
    font-size: clamp(28px, 2.5vw, 48px);
  }
  /* .content-heading p,
  .heading p {
    font-size: clamp(16px, 1.5vw, 28px);
  } */
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .heading h1 {
    font-size: clamp(35px, 4.69vw, 48px);
  }
}

@media screen and (max-width: 1024px) {
  .container {
    padding: 0 15px;
    margin: auto;
  }
  .btn {
    padding: 10px;
  }
  .content-heading h1 {
    font-size: clamp(26px, 4vw, 32px);
  }
}
@media screen and (max-width: 767px) {
  .heading h1 {
    font-size: 24px;
  }
  .btn {
    padding: 5px 10px;
  }
}
